import React from "react";
import "./App.css";
import mainImage from "./images/main-image.png";
function App() {
  return (
    <div className="App">
      <div className="image">
        <img src={mainImage} />
      </div>
      <div className="block">
        <div className="block__content">
          <div className="block__content-title">Stay tuned for updates!</div>
          <div className="block__content-description">
            Stay tuned for updates! We're working hard to get everything ready!
            Check back soon for more information
          </div>
        </div>
        <div className="block__content">
          <div className="block__content-title">
            Bleiben Sie dran für Updates!
          </div>
          <div className="block__content-description">
            Wir arbeiten hart daran, alles vorzubereiten! Schauen Sie bald
            wieder für weitere Informationen vorbei.
          </div>
        </div>
        <div className="block__content">
          <div className="block__content-title">
            ¡Manténganse al tanto para actualizaciones!
          </div>
          <div className="block__conten-description">
            Estamos trabajando arduamente para tener todo listo. Vuelva pronto
            para más información.
          </div>
        </div>
        <div className="block__content">
          <div className="block__content-title">
            Fique ligado para atualizações!
          </div>
          <div className="block__content-description">
            Estamos trabalhando duro para deixar tudo pronto. Volte em breve
            para mais informações.
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
